import { useEffect } from 'react';
import { getToken, saveToken } from './token-helper';

function App() {
  useEffect(() => {
    const token = getToken();
    if (token) {
      window.top?.postMessage(token, '*');
    } else {
      window.top?.postMessage('bm8tY29wdC10b2tlbg==', '*');
    }
    window.addEventListener('message', saveToken);

    return () => {
      window.removeEventListener('message', saveToken);
    };
  }, []);
  return <div>copt-session</div>;
}

export default App;
